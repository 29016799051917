body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: hidden;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5em;
}

/* Default styles for image container */
.image-container {
  position: absolute;
  text-align: left;
  width: 100em; /* increased from 100vw */
  height: 60em; /* increased from 24.5em */
  bottom: 100px; 
  z-index: -1;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: -1;
}

.text-container {
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  margin-top: -4px;
  padding: 0em;
}

@media (min-width: 769px) and (max-width: 1473px) {
  /* Styles specific to small laptops or tablets go here */
  .image-container {
    position: absolute;
    text-align: left;
    width: 100vw;
    height: 24.5em;
    bottom: 150px; 
    z-index: -1;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: -1;
  }

  .text-container {
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    margin-top: -4px;
    padding: 0;
  }

}



@media (max-width: 768px) {
  .image-container {
    position: absolute;
    text-align: left;
    width: 100vw;
    height: 24.5em;
    bottom: 40px; 
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
  }

  .text-container {
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    margin-top: -10px;
    padding: 0;
  }
}

@media (max-width: 425px) {
  .image-container {
    position: absolute;
    text-align: left;
    width: 100vw;
    height: 24.5em;
    bottom: 40px; 
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
  }

  .text-container {
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    margin-top: -40px;
    padding: 0;
  }
}

@media (max-width: 320px) {

  .text-container {
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    margin-top: -80px;
    padding: 0;
  }
}
