.mads-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* center content horizontally */
  justify-content: center; /* center content vertically */
  min-height: 100vh; /* takes full viewport height */
  text-align: center; /* center the text inside each child */
}

:root {
  --iframe-width: 550px; /* Adjust the width as needed */
  --iframe-height: 360px; /* Adjust the height as needed */
}

.video-description {
  width: 640px; /* Match the iframe width */
  text-align: left;
}

iframe {
  border: none;
}
@media (max-width: 768px) {
  .mads-container {
    padding-top: 7em;
    display: flex;
    flex-direction: column;
    align-items: center; /* center content horizontally */
    justify-content: center; /* center content vertically */
    min-height: 100vh;
     /* takes full viewport height */
}

.video-description{
  padding: 2px;
  margin-right: 2px; /* Keeps some distance from the end of the screen */
  max-width: calc(100% - 7px); /* container width minus padding and margin */
  word-wrap: break-word; /* This ensures that long words also break if they exceed the width of the container */
}

.video-description p {
    font-size: 3vw; /* Font size based on viewport width, adjust as needed */
    overflow-wrap: break-word; /* Allows the text to wrap inside the container */
}
  
:root {
    --iframe-width: 100%; /* Adjust the width as needed */
    --iframe-height: 180px; /* Adjust the height as needed */
  }
}

