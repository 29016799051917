.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.carousel-wrapper {
    width: 50%;
}


.carousel-image-container {
    position: relative;
    padding-top: 50.25%;
    overflow: hidden;
}


.carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-description {
    width: 640px;
    text-align: left;
    margin-top: 2px;
}



.arrow {
    cursor: pointer;
    opacity: 20%;
}

.arrow:hover {
    color: blue !important;
    opacity: 50%;
}

/* Reset default styles from react-slick */
.slick-next::before,
.slick-prev::before {
    content: none !important;
}

@media (max-width: 768px) {
    .carousel-container {
        padding-top: 8.3em;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;

    }

    .carousel-wrapper {
        width: 100%;
    }

    .image-description{
        padding: 2px;
        margin-right: 2px; /* Keeps some distance from the end of the screen */
        max-width: calc(100% - 70px); /* container width minus padding and margin */
        word-wrap: break-word; /* This ensures that long words also break if they exceed the width of the container */
    }
    
    .image-description p {
        font-size: 3vw; /* Font size based on viewport width, adjust as needed */
        overflow-wrap: break-word; /* Allows the text to wrap inside the container */
    }

}