@font-face {
  font-family: "NimbusSans";
  src: url("../fonts/NimbusSanL-Reg.otf") format("truetype");
}

.info-container {
    padding: 5em;
    max-width: 800px;
    margin: auto;
    font-family: "NimbusSans", sans-serif;
    line-height: 1.5;
    text-align: center;
  }

/* Media query for smaller screens (e.g., mobile) */
@media (max-width: 768px) {
  .info-container {
    padding-top: 11em !important; /* Adjust the value as needed for more distance */
    padding: 5em;
    max-width: 700px;
    margin: auto;
    font-family: "NimbusSans", sans-serif;
    line-height: 1.5;
    text-align: center;
    font-weight: 1;
  }
}
  h2, h3 {
    color: #000000;
  }
  
  p, li {
    color: #000000;
  }
  
  ul {
    padding-left: 0; /* Removing left padding */
    list-style-type: none; /* Removing bullets */
    text-align: center;
  }
  
  hr {
    border: 0;
    height: 1px;
    background: #ddd;
  }
  